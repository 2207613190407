<template>
  <div class="contain">
    <div class="contain-header">
      <div style="display: flex;align-items: center;margin-right: 20px">
        <span style="width: 100px;font-weight: bold">赛事名称 :</span>
        <el-input clearable v-model="listForm.eventName" placeholder="请输入赛事名称" size="small"></el-input>
      </div>
      <div>
        <el-button size="small" style="background-color: #bf353b;color: #fff" @click="searchList">查 询
        </el-button>
        <el-button size="small" @click="resetList">重 置</el-button>

        <el-button size="small" style="background-color: #791d21;color: #fff;margin-left: 50px"
                   @click="toCreate">创 建 赛 事
        </el-button>

      </div>
    </div>
    <div class="contain-table">
      <el-table
          :data="tableData"
          :header-cell-style="{ background:'#b14040',color:'#fff'}"
          border
          style="width: 100%">
        <el-table-column label="赛事名称" align="center">
          <template slot-scope="scope">
            <span style="color: #b14040">{{ scope.row.eventName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            <span style="color: #b14040">{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="状态" align="center">
          <template slot-scope="scope">
            <el-tag style="color: #fff" size="medium"
                    :color="scope.row.status === 1 ? '#f46e55' : scope.row.status === 0 ? '#909399' : '#606266'">
              {{ scope.row.status === 1 ? '进行中' : scope.row.status === 0 ? '未开始' : '已结束' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteById(scope.row.id)">删 除</el-button>
            <el-button type="text" @click="toDetail(scope.row.id)" v-if="scope.row.status===1">查 看
            </el-button>
            <!--                        <el-button type="text" @click="toEdit(scope.row.id)" v-if="scope.row.status===0">编 辑</el-button>-->
            <el-button type="text" @click="findLink(scope.row.id)">查看链接</el-button>
            <el-button type="text" v-if="scope.row.status === 1" @click="setEvent(scope.row.id)">关闭比赛
            </el-button>
            <el-button type="text" v-if="scope.row.status === 0" @click="openEvent(scope.row.id)">开启比赛
            </el-button>

            <!--                        <el-button type="text" @click="seeScore(scope.row)">成绩查看</el-button>-->

            <el-button type="text" @click="changeYunSaiEvent(scope.row.id,0)"
                       v-if="scope.row.publishStatus===0">发布至云赛
            </el-button>
            <el-button type="text" @click="changeYunSaiEvent(scope.row.id,1)"
                       v-if="scope.row.publishStatus===1">撤回发布
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="contain-page">
      <el-pagination
          background
          layout="prev, pager, next"
          :page-size="5"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        title="赛事链接"
        :visible.sync="seeLink"
        width="30%"
        center
    >
      <div class="dialog-form">
        <!--                <div class="dialog-title">-->
        <!--                    <span>主持端链接：</span>-->
        <!--                    <el-input v-html="url.hostUrl" style="width: 80%" placeholder="暂无" size="small"></el-input>-->
        <!--                </div>-->
        <!--                <div class="dialog-title">-->
        <!--                    <span>评委端链接：</span>-->
        <!--                    <el-input v-html="url.judgeUrl" style="width: 80%" placeholder="暂无" size="small"></el-input>-->
        <!--                </div>-->
        <div class="dialog-title">
          <span>大屏端链接：</span>
          <el-input v-html="url.homeUrl" style="width: 80%" placeholder="暂无" size="small"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button style="width: 100px ;background-color: #7c292c;color: #fff" @click="copy"
                           size="small">一 键 复 制</el-button>
				<el-button style="width: 100px ;background-color: #bf353b;color: #fff" @click="seeLink = false"
                   size="small">关 闭</el-button>
			  </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  eventList,
  deleteEvent,
  changeEvent,
  findLink,
  publishEvent,
  saveEventToYunSai,
  delPublishEvent,
  delEventToYunSai
} from "../../api/event";

export default {
  name: "eventList",
  data() {
    return {
      seeLink: false,
      url: '',
      listForm: {
        eventName: "",
        pageIndex: 1,
        pageSize: 5
      },
      tableData: [],
      total: null
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    changeYunSaiEvent(eventId, status) {
      if (status === 0) {
        this.$confirm('确定发布到云赛系统吗, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const {data} = await publishEvent(eventId)
          if (data.code === 200) {
            this.saveEventToYunSai(data.data);
          }
        })
      } else {
        this.$confirm('确定撤回发布吗, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const {data} = await delPublishEvent(eventId)
          if (data.code === 200) {
            this.delEventToYunSai(data.data);
          }
        })
      }
    },

    async saveEventToYunSai(obj) {
      const {data} = await saveEventToYunSai(obj)
      if (data.code === 200) {
        this.getList()
        this.$message.success('发布成功');
      }
    },
    async delEventToYunSai(obj) {
      const {data} = await delEventToYunSai(obj)
      if (data.code === 200) {
        this.getList()
        this.$message.success('撤回成功');
      }
    },
    //复制
    copy() {
      // let content = '主持端：' + this.url.hostUrl + '\n' + '评委端：' + this.url.judgeUrl + '\n' + '大屏端：' + this.url.homeUrl;
      let content = this.url.homeUrl;

      navigator.clipboard.writeText(content).then(() => {
        this.$message.success('复制成功')
      }).catch(() => {
        this.$message.warning('复制失败')
      })
    },
    //创建赛事
    toCreate() {
      this.$router.push('/createEvent')
    },
    //查看成功
    seeScore(obj) {
      if (obj.status !== 2) {
        this.$message.warning('比赛未结束暂无成绩')
        return false
      }
    },
    //编辑页面
    toEdit(id) {
      this.$router.push('/eventEdit?id=' + id)
    },
    //查看页面
    toDetail(id) {
      this.$router.push('/eventDetail?id=' + id)
    },
    //查看链接
    async findLink(id) {
      const {data} = await findLink(id)
      if (data.code === 200) {
        this.url = data.data
        this.seeLink = true
      }
    },
    //分页方法
    handleCurrentChange(val) {
      console.log(val)
      this.listForm.pageIndex = val
      this.getList()
    },
    handleSizeChange(val){
      this.listForm.pageSize = val
      this.getList()
      
    },
    async getList() {
      const {data} = await eventList(this.listForm)
      if (data.code === 200) {
        this.tableData = data.data.data
        this.total = data.data.total
      }
    },
    // 删除比赛
    deleteById(id) {
      this.$confirm('删除比赛, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteEvent(id).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.message)
            //⭐1.利用Math.ceil算出新的分页 total为什么要-1,因为删完后total还没更新
            let totalPage = Math.ceil((this.total - 1) / this.listForm.pageSize)
//⭐2.计算出当前页码
            this.listForm.pageIndex = this.listForm.pageIndex > totalPage ? totalPage : this.listForm.pageIndex
//⭐3.如果页码为1的话，则无效
            this.listForm.pageIndex = this.listForm.pageIndex < 1 ? 1 : this.listForm.pageIndex
    
            this.getList()
          }
        })
      })
    },
    searchList() {
      if (!this.listForm.eventName) {
        return this.$message.warning('请填写赛事名称')
      } else {
        this.getList()
      }
    },
    resetList() {
      this.listForm.eventName = ''
      this.getList()
    },
    setEvent(id) {
      this.$confirm('关闭比赛, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeEvent(id).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.message)
            this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消关闭'
        });
      });
    },
    openEvent(id) {
      this.$confirm('开启比赛, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeEvent(id).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.message)
            this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消开启'
        });
      });
    }
  }
}
</script>

<style scoped lang="less">
.contain {
  margin: 0px;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: url("../../assets/images/homeBanner.png") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;

  .dialog-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dialog-title {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .contain-header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .contain-table {
    width: 100%;
    background-color: #bf353b;
  }

  .contain-page {
    margin: 20px 0;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #bf353b;
    }
  }
}
</style>
